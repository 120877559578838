import React from "react";

import GlobalStateProvider from "../../context/GlobalStateProvider";
import { GlobalStyles, Primary } from "./Layout.styles";

const Layout = ({ children }) => {
  return (
    <GlobalStateProvider>
      <GlobalStyles />
      <Primary>{children}</Primary>
    </GlobalStateProvider>
  );
};

export default Layout;
