import React from "react";
import Modal from "react-modal";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  Modal.setAppElement("#___gatsby");
};
