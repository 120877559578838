import styled, { createGlobalStyle } from "styled-components";

export const Primary = styled.main`
  .br-8 {
    border-radius: 0.5rem;
  }

  .br-4 {
    border-radius: 0.25rem;
  }

  .text-center {
    text-align: center;
  }

  .text-30 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 100;
  }

  .text-24 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .text-16 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .text-14 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .icon-60 {
    width: 3.75rem;
    height: 3.75rem;
  }

  .icon-50 {
    width: 3.125rem;
    height: 3.125rem;
  }

  .icon-38-48 {
    width: 2.375rem;
    height: 3rem;
  }

  .icon-32 {
    width: 2rem;
    height: 2rem;
  }

  .icon-24 {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --white: #fff;
    --error: #F04438;
    --grey-500: #CCCCCC;
    --grey-600: #70707B;
    --grey-700: #333333;
    --grey-800: #1F1F1F;
    --grey-900: #18181B;
    --border: 1px solid var(--grey-700);
  }

  html {
    line-height: 1.15;
    font-family: 'HelveticaNeueCyr', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    *::selection {
      background: #64a0ec;
      color: #fff;
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    outline: 0;
    overscroll-behavior-x: none;
	  font-style: normal;
  }

  body {
    line-height: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--black);
    color: var(--white);

    * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &:after, &:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }

    &.menu-open {
      overflow: hidden;
    }
  }

  sub, sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em
  }

  sub {
    bottom: -0.25em
  }

  article, aside, details, figcaption, figure,
  footer, header, menu, nav, section {
    display: block
  }

  img {
    width: 100%;
    height: 100%;
  }

  p {
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5rem;
  }

  a {
    text-decoration: none;
    color: inherit;

    @media (min-width: 1921px) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  ul {
    list-style: none;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible
  }

  // Remove the inheritance of text transform in Edge, Firefox, and IE. 1. Remove the inheritance of text transform in Firefox.
  button,
  select {
    text-transform: none
  }

  // Correct the inability to style clickable types in iOS and Safari.
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button
  }

  // Remove the inner border and padding in Firefox.
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0
  }

  //noinspection CssInvalidPseudoSelector
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::placeholder {
    color: var(--gray);
    opacity: 1;
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  button {
    outline: none;
    border: none;

    &:active, &:focus {
      outline: none
    }
  }

  input,
  textarea {
    width: 100%;
    height: 2.75rem;
    padding: 0.625rem 0.875rem;
    outline: none;
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem;
    letter-spacing: 0;
    color: var(--white);
    background: var(--grey-800);
    border: var(--border);
    border-radius: 0.25rem;
    resize: none;

    &:focus {
      border: 1px solid var(--white);
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
    }

    &.error, &.error:focus {
      /* outline: 1px solid var(--red); */
    }

    &::placeholder {
      color: var(--grey-600);
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.25rem;
    }
  }

  .btn {
    outline: 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &.small{
      font-size: 1rem;
      padding: 0.75rem 2.5rem 0.5rem;
    }
  }

  .cuh {
    cursor: help;
  }

  .cup {
    cursor: pointer;
  }

  .icon {
    max-width: max-content;
  }

  .rotate90 {
    transform: rotate(90deg);
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .rotate270 {
    transform: rotate(270deg);
  }

  .shadow {
    box-shadow: 0 0.25rem 1.875rem rgba(148, 160, 190, 0.15);
  }

  .paragraph-color {
    color: #8C94A6;
  }

  .blue-text {
    color: var(--blue)
  }

  .bold-text {
    font-weight: 500;
  }

  .normal-text {
    font-weight: 400;
  }

  .claim-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }

  .opacity50 {
    opacity: 50%;
  }

  .hide-mob {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .hide-desktop {
    display: none;
    @media (max-width: 767px) {
      display: flex;
    }
  }

  .error-msg {
    display: block;
    margin-top: 0.25rem;
    color: var(--error);
    transition: opacity .5s;
    white-space: nowrap;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .home-mobile-cookie{
    #cookiescript_badge {
      bottom: 0.625rem !important;  
    }
  }
`;
